import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../styles/CpExposFormStepTwo.css';
import { eachDayOfInterval, parseISO, isWithinInterval, format, formatISO } from 'date-fns';
import { CircularProgress, Fade, IconButton, InputLabel } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

const CpFormStepTwo = ({ product, onNext, onPrevious }) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [reservedDates, setReservedDates] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [manualStartDate, setManualStartDate] = useState('');
  const [manualEndDate, setManualEndDate] = useState('');
  const [blockedDates, setBlockedDates] = useState([]);
  const [loading, setLoading] = useState(true);

  const today = new Date();
  const minDays = product.productTagIds[0] === 2 ? 21 : 5;


  const fetchReservedDates = async () => {
    try {
      const responseRentalSchedule = await fetch(`${process.env.REACT_APP_API_SOURCE}/api/v1/CpExpos/expos/rentalSchedule?productId=${product.id}`);
      const responseRestrictedDates = await fetch(`${process.env.REACT_APP_API_SOURCE}/api/v1/CpExpos/expos/restrictedDates`) ;
      // Filtrer et transformer les réservations
      const eachBlockedDates = []
      if(responseRestrictedDates.ok){
        const restrictedDates = await responseRestrictedDates.json();
        restrictedDates.forEach(date => {
          eachBlockedDates.push({ start: new Date(date), end: new Date(date) })
        });
      }
      if (responseRentalSchedule.ok) {
        const data = await responseRentalSchedule.json();
        const allReservedDates = data.flatMap(reservation => {
          if (reservation.pickupDate && reservation.returnDate) {
            try {
              const pickupDate = parseISO(reservation.pickupDate);
              const returnDate = parseISO(reservation.returnDate);
              if(reservation.productId != process.env.REACT_APP_BLOCKED_DATES_ID){
                return eachDayOfInterval({ start: pickupDate, end: returnDate });
              }else{
                eachBlockedDates.push({ start: pickupDate, end: returnDate });
              }

            } catch (error) {
              console.error('Erreur lors du parsing des dates :', error);
              return []; // Retourner un tableau vide pour ignorer cette réservation
            }
          }
          return []; // Ignorer si pickupDate ou returnDate est null
        });
        setBlockedDates(eachBlockedDates);
        setReservedDates(allReservedDates);
        setLoading(false);
      } else {
        console.error('Erreur lors de la récupération des dates réservées');
      }
    } catch (error) {
      console.error('Erreur :', error);
    }
  };
  

  useEffect(() => {
    fetchReservedDates();
  }, [product.id]);

  const normalizeDate = (date) => {
    const normalized = new Date(date);
    normalized.setHours(0, 0, 0, 0);
    return normalized;
  };

  const isBlockedDay = (date) => {
    const isBlocked = blockedDates.some((blockedDate) => {
      const startDate = normalizeDate(blockedDate.start);
      const endDate = normalizeDate(blockedDate.end);
      const currentDate = normalizeDate(date);

      return currentDate >= startDate && currentDate <= endDate;
    });

    return isBlocked

  }

  const isDisabledDay = (date) => {
  
    const day = date.getDay();
    const isWeekend = day === 0 || day === 6;
  
    return isWeekend
  };
  

  const isReservedDay = (date) => {
    return reservedDates.some(reservedDate => reservedDate.toDateString() === date.toDateString());
  };

  const doesOverlapWithReservedDates = (startDate, endDate) => {
    const selectedRange = eachDayOfInterval({ start: startDate, end: endDate });
    return selectedRange.some(selectedDate => reservedDates.some(reservedDate => reservedDate.toDateString() === selectedDate.toDateString()));
  };

  const getValidDaysCount = (startDate, endDate) => {
    const allDays = eachDayOfInterval({ start: startDate, end: endDate });
    const validDays = allDays.filter(day => !isReservedDay(day));
    return validDays.length;
  };

  const handleDateChange = (value) => {
    const [startDate, endDate] = value;

    if (doesOverlapWithReservedDates(startDate, endDate)) {
      setErrorMessage('La plage de dates sélectionnée chevauche une réservation existante.');
      setSelectedDates([]);
      return;
    }

    const validDaysCount = getValidDaysCount(startDate, endDate);
    if (validDaysCount < minDays) {
      setErrorMessage(`La réservation doit inclure au moins ${minDays} jours valides.`);
      setSelectedDates([]);
      return;
    }

    setErrorMessage('');
    setSelectedDates(value);
    setManualStartDate(format(startDate, 'yyyy-MM-dd'));
    setManualEndDate(format(endDate, 'yyyy-MM-dd'));

    

  };

  const handleManualDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') {
      setManualStartDate(value);
    } else if (name === 'endDate') {
      setManualEndDate(value);
    }
  };

  const handleManualDateSubmit = () => {
    const startDate = parseISO(manualStartDate);
    const endDate = parseISO(manualEndDate);
    const formattedStartDate = formatISO(startDate);
    const formattedEndDate = formatISO(endDate); 

    if (doesOverlapWithReservedDates(startDate, endDate)) {
      setErrorMessage('La plage de dates sélectionnée chevauche une réservation existante.');
      return;
    }

    const validDaysCount = getValidDaysCount(startDate, endDate);
    if (validDaysCount < minDays) {
      setErrorMessage(`La réservation doit inclure au moins ${minDays} jours valides.`);
      return;
    }

    setSelectedDates([formattedStartDate, formattedEndDate]);
    setErrorMessage('');
  };

  const isSelectionValid = selectedDates.length === 2 && !errorMessage;

  const tileClassName = ({ date }) => {
    if (isReservedDay(date)) {
      return 'reserved';
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [activeStartDate, setActiveStartDate] = useState(new Date());

  return (
    loading && (
          <div className='loader-container'>
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? '800ms' : '0ms',
              }}
              unmountOnExit
            >
              <CircularProgress color="secondary" />
            </Fade>
          </div>
        )
        ||
    <>
    
      <div className="cp-form-step-two">
        <div className='calendar-container'>
          <div style={{display: 'flex'}}>
          <h2 className='product-title'>{product.name}</h2>
          </div>
          <p>Veuillez sélectionner une plage de dates valides via le calendrier, ou entrez directement les dates manuellement. ({minDays} jours minimum)</p>
          <div className='calendar-legend'>
            <div className='reserved-days-legend'></div> <p>Dates déjà réservées</p>
            <div className='avalaible-days-legend'></div> <p>Dates sélectionnables</p>
            <div className='grayed-days-legend'></div> <p>Installation et démontage indisponnibles</p>
          </div>
          <Calendar
            hover={selectedDates.startDate}
            selectRange={true}
            showDoubleView={!isMobile}
            minDate={today}
            tileDisabled={({ date }) => isDisabledDay(date) || isReservedDay(date) || isBlockedDay(date)}
            tileClassName={tileClassName}
            onChange={handleDateChange}
            activeStartDate={activeStartDate}
            value={selectedDates}
            goToRangeStartOnSelect={false}
            onActiveStartDateChange={(data) => {
              if (data.action !== "onChange") {
                  setActiveStartDate(data.activeStartDate);
              }
          }}
          />
          <p>Entrer les dates manuellement:</p>
          <div className="manual-date-inputs">
            <InputLabel>Date de début :</InputLabel>
            <input
              type="date"
              name="startDate"
              value={manualStartDate}
              onChange={handleManualDateChange}
            />
            <InputLabel>Date de fin :</InputLabel>
            <input
              type="date"
              name="endDate"
              value={manualEndDate}
              onChange={handleManualDateChange}
            />
            <div>
              <button className='btn-change-state' onClick={handleManualDateSubmit}>Valider les dates</button>
            </div>
          </div>
        </div>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        {selectedDates.length === 2 && (
          <div className="selected-dates">
            <p>Date de début : {new Intl.DateTimeFormat('fr-FR', { dateStyle: 'long' }).format(new Date(selectedDates[0]))}</p>
            <p>Date de fin : {new Intl.DateTimeFormat('fr-FR', { dateStyle: 'long' }).format(new Date(selectedDates[1]))}</p>

          </div>
        )}

        <div>
          <button id='change-step-btn-2' className="btn-change-step" disabled={!isSelectionValid} onClick={() => { onNext(selectedDates) }}>Suivant</button>
        </div>
      </div>
    </>
  );
};

export default CpFormStepTwo;
